import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';

const routes = [
  {
      path: '/notifications',
      component: asyncComponent(() => import('../Notification/Notifications')),
      exact: false
  },
    {
        path: '/users',
        component: asyncComponent(() => import('../Users/Users')),
        exact : false
    },
  {
    path: '/candidates',
    component: asyncComponent(() => import('../Candidates/Candidates')),
    exact : false
  },
  {
    path: '/templates',
    component: asyncComponent(() => import('../Templates/Templates')),
    exact : false
  },
  {
    path: '/static-content',
    component: asyncComponent(() => import('../StaticContent/StaticContent')),
    exact : false
  },
   {
    path: '/manage-advertisements',
    component: asyncComponent(() => import('../Advertisements/Advertisements')),
    exact : false
  },
  {
    path: '/sponsored-advertisements',
    component: asyncComponent(() => import('../SponsoredAdvertisements/SponsoredAdvertisements')),
    exact : false
  },
  {
    path: '/featured-advertisements',
    component: asyncComponent(() => import('../FeaturedAdvertisements/FeaturedAdvertisements')),
    exact : false
  },
  {
    path: '/affiliates',
    component: asyncComponent(() => import('../Affiliates/Affiliates')),
    exact : false
  },
  {
    path: '/manage-category',
    component: asyncComponent(() => import('../Category/Category')),
    exact : false
  },
  {
    path: '/manage-poll',
    component: asyncComponent(() => import('../Poll/Poll')),
    exact : false
  },
  {
    path: '/settings',
    component: asyncComponent(() => import('../Settings/Settings')),
    exact : false
  },
  {
    path: '/change-password',
    component: asyncComponent(() => import('../ChangePassword/ChangePassword')),
    exact : false
  },
  {
    path: '/404',
    component: asyncComponent(() => import('../Page/404')),
    exact : false
  },
  {
    path: '/dashboard',
    component: asyncComponent(() => import('../Widgets/index.js')),
    exact : true
  },
    {
        path: '/feedback',
        component: asyncComponent(() => import('../Feedback/Feedback')),
        exact: true
    },
  {
    path: '/',
    component: asyncComponent(() => import('../Widgets/index.js')),
    exact : false
  },

];

class AppRouter extends Component {
  render() {
    const { style } = this.props;
    return (
      <div style={style}>
        <Switch>
          {routes.map(singleRoute =>
            <Route
              exact={singleRoute.exact}
              key={singleRoute.path}
              path={singleRoute.path}
              {...singleRoute}
            />
          )}
        </Switch>
      </div>
    );
  }
}

export default AppRouter;
