export default {
  apiUrl: 'http://yoursite.com/api/',
};

const siteConfig = {
  siteName: "Detroit Action",
  siteIcon: "ion-flash",
  footerText: "Detroit Action ©2023",
};
const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault',
};
const language = 'english';

const jwtConfig = {
  fetchUrl: '/api/',
  secretKey: 'secretKey',
};

export { siteConfig, language, themeConfig, jwtConfig };
