// USED API URL
export const API_URLS = {
    SIGN_IN                 : "api/cms/login",
    CHANGE_PASSWORD         : "api/cms/change-password",
    SIGNOUT                 : "api/cms/sign-out",

    //USER
    EDIT_USER                : "api/cms/users/update",
    DELETE_USER              : "api/cms/users/delete",
    GET_USER_LIST            : "api/cms/users/list",
    GET_AFFILIATE_TYPE       : "api/cms/users/drop-down",

    //USER
    EDIT_CANDIDATE                : "api/cms/candidates/update",
    GET_CANDIDATE_LIST            : "api/cms/candidates/list",

    //TEMPLATES
    EDIT_TEMPLATE            : "api/cms/templates/update",
    GET_TEMPLATE_LIST        : "api/cms/templates/list",

    //STATIC CONTENT
    EDIT_STATIC_CONTENT      : "api/cms/static-content/update",
    GET_STATIC_CONTENT_LIST  : "api/cms/static-content/list",

    //ADVERTISEMENTS
    GET_ADVERTISEMENT_LIST  : "api/cms/advertisements/list",
    DELETE_ADVERTISEMENT    : "api/cms/advertisements/delete",
    GET_PAGE_LIST           : "api/cms/advertisements/page-list",
    ADD_ADVERTISEMENT       : "api/cms/advertisements/create",
    EDIT_ADVERTISEMENT      : "api/cms/advertisements/update",

    //SPONSORED ADVERTISEMENTS
    GET_SPONSORED_ADVERTISEMENT_LIST  : "api/cms/sponsored-advertisements/list",
    DELETE_SPONSORED_ADVERTISEMENT    : "api/cms/sponsored-advertisements/delete",
    ADD_SPONSORED_ADVERTISEMENT       : "api/cms/sponsored-advertisements/create",
    EDIT_SPONSORED_ADVERTISEMENT: "api/cms/sponsored-advertisements/update",

    //FEATURED ADVERTISEMENTS
    GET_FEATURED_ADVERTISEMENT_LIST  : "api/cms/featured-advertisements/list",
    DELETE_FEATURED_ADVERTISEMENT    : "api/cms/featured-advertisements/delete",
    ADD_FEATURED_ADVERTISEMENT       : "api/cms/featured-advertisements/create",
    EDIT_FEATURED_ADVERTISEMENT      : "api/cms/featured-advertisements/update",

    //AFFILIATES
    GET_AFFILIATE_LIST  : "api/cms/affiliates/list",
    ADD_AFFILIATE       : "api/cms/affiliates/create",
    EDIT_AFFILIATE      : "api/cms/affiliates/update",

    //CATEGORY
    EDIT_CATEGORY           : "api/cms/polls/category/update",
    DELETE_CATEGORY         : "api/cms/polls/category/delete",
    GET_CATEGORY_LIST       : "api/cms/polls/category/list",
    ADD_CATEGORY            : "api/cms/polls/category/create",
    GET_CATEGORY_ID         : "api/cms/polls/category/get",

    //POLL
    EDIT_POLL           : "api/cms/polls/update",
    DELETE_POLL         : "api/cms/polls/delete",
    GET_POLL_LIST       : "api/cms/polls/list",
    ADD_POLL            : "api/cms/polls/create",

    //OPTIONS
    ADD_OPTION           : "api/cms/polls/create-option",
    EDIT_OPTION           : "api/cms/polls/update-option",
    DELETE_OPTION: "api/cms/polls/delete-option",

    //NOTIFICATIONs
    NOTIFICATION_LIST: "api/cms/notification/list",
    NOTIFICATION_TYPES: "api/cms/notification/drop-down",
    NOTIFICATION_CREATE: "api/cms/notification/create",

    // Dashboard
    DASHBOARD: "api/cms/dashboard",

    // Feedback
    FEEDBACK: "api/cms/feedback/list",
};

// USED WEBSITE_URLS URL
export const WEBSITE_URLS = {
    SIGNIN              : "/signin",
    CHANGE_PASSWORD     : "/change-password",
    DASHBOARD           : "/dashboard",
    SETTINGS            : "/settings",
    NOT_FOUND           : "/404",

    // USER MANAGEMENT
    USERS               : "/users/:userType",

    //SURVEY MANAGEMENT
    SURVEYS               : "/surveys/",

    // POLL MANAGEMENT
    POLL               : "/poll/",


};
