import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { connect } from 'react-redux';
import { WEBSITE_URLS } from './helpers/path'
import App from './containers/App/App';
import asyncComponent from './helpers/AsyncFunc';

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn ? <Component {...props} /> : 
      <Redirect
        to={{
          pathname: WEBSITE_URLS.SIGNIN,
          state: { from: props.location }
        }}
      />
    }
  />
);
const PublicRoutes = ({ history, isLoggedIn }) => {
  console.log("PublicRoutes -> isLoggedIn", isLoggedIn)
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route
          exact
          path={'/'}
          component={asyncComponent(() => import('./containers/Page/signin'))}
        />
        <Route
          exact
          path={WEBSITE_URLS.SIGNIN}
          component={asyncComponent(() => import('./containers/Page/signin'))}
        />
        <RestrictedRoute
          path="/"
          component={App}
          isLoggedIn={isLoggedIn}
        />
      </Switch>
    </ConnectedRouter>
  );
};

export default connect(state => ({
  isLoggedIn: state.Auth.authToken !== null
}))(PublicRoutes);
