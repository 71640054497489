const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (authToken,id) => {
    localStorage.setItem('isLoggedIn', true)    
    localStorage.setItem('id', id)    
    localStorage.setItem('authToken', JSON.stringify(authToken))
    return {
      type: actions.LOGIN_REQUEST,
      // ...data
    }
  },
  logout: () => {
    localStorage.clear()
    return {
      type: actions.LOGOUT
    }
  }
};
export default actions;
