const options = [
  {
    key: 'dashboard',
    label: 'sidebar.dashboard',
    leftIcon: 'ion-podium',
  },
  {
    key: 'users',
    label: 'sidebar.users',
    leftIcon: 'ion-ios-people',
  },
  {
    key: 'candidates',
    label: 'sidebar.candidates',
    leftIcon: 'ion-ios-people',
  },
  // {
  //   key: 'manage-category',
  //   label: 'sidebar.manageCategory',
  //   leftIcon: 'ion-ios-list',
  // },
  /*{
    key: 'manage-sub-category',
    label: 'sidebar.manageSubCategory',
    leftIcon: 'ion-ios-list',
  },*/
  /* {
    key: 'templates',
    label: 'sidebar.templates',
    leftIcon: 'ion-paintbucket',
  }, */
  {
    key: "static-content",
    label: "sidebar.staticContent",
    leftIcon: "ion-ios-paper"
  },
  {
    key: "manage-advertisements",
    label: "sidebar.manageAdvertisements",
    leftIcon: "ion-ios-list"
  },
  {
    key: "sponsored-advertisements",
    label: "sidebar.sponsoredAdvertisements",
    leftIcon: "ion-ios-list"
  },
  {
    key: "featured-advertisements",
    label: "sidebar.featuredAdvertisements",
    leftIcon: "ion-ios-list"
  },
  {
    key: "affiliates",
    label: "sidebar.affiliates",
    leftIcon: "ion-ios-list"
  },
  /* {
    key: "settings",
    label: "sidebar.settings",
    leftIcon: "ion ion-wrench"
  }, */
  {
    key: "change-password",
    label: "sidebar.changePassword",
    leftIcon: "ion-ios-settings"
  },
  {
    key: "manage-category",
    label: "sidebar.manageCategory",
    leftIcon: "ion-ios-list"
  },
  {
    key: "manage-poll",
    label: "sidebar.managePoll",
    leftIcon: "ion-ios-list"
  },
    {
        key: "notifications",
        label: "sidebar.notifications",
        leftIcon: "ion-ios-bell"
    }, {
    key: "feedback",
    label: "sidebar.feedback",
    leftIcon: "ion-chatbubbles"
  },

];
export default options;
